import { Box, Container, Grid } from "@mui/material"
import { Sectional } from "layout/Sectional"
import React, { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

type PeopleJSON = {
    people: PeopleList
}

type PeopleList = {
    working_board: PeopleItem[]
    advisory_board: PeopleItem[]
    staff_members: PeopleItem[]
}

type PeopleItem = {
    first_name: string
    last_name: string
    title: string
    description: string,
    image: string
}

type PeopleProperties = {
    people: string,
    children: ReactNode | undefined
}

function supportedLangs(lang: string) {
    return lang === "en" || lang === "es";
}

export const PeopleWidget: React.FC<PeopleProperties> = ({ people }) => {

    let params = useParams();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [peoples, setPeoples] = useState<PeopleJSON>();

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');

    useEffect(() => {
        const lang: string = navigator.language.split("-")[0];
        const peopleUrl = supportedLangs(lang) ? `/globals/${lang}/people.json` : "/globals/en/people.json";
        fetch(peopleUrl, { 'headers': requestHeaders })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setPeoples(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])

    if (peoples) {
        const workingBoard: PeopleItem[] = peoples.people.working_board;
        const advisoryBoard: PeopleItem[] = peoples.people.advisory_board;
        const staffMembers: PeopleItem[] = peoples.people.staff_members;
        
        return (
            <>  
                <Sectional title="Staff Members">
                    <Grid container spacing={ 2 } >
                        { 
                            staffMembers.map((member) => {
                                const key = member.first_name + "_" + member.last_name
                                return (
                                    <Grid item key={ key } {...{ xs: 12, sm: 6, md: 4, lg: 3 }} minHeight={160}>
                                        <h3>
                                            { member.first_name } {member.last_name}
                                        </h3>
                                        <h4>
                                            { member.title}
                                        </h4>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Sectional>
                
                <Sectional title="Board of Directors">
                    <Grid container spacing={ 2 } >
                        { 
                            workingBoard.map((member) => {
                                const key = member.first_name + "_" + member.last_name
                                return (
                                    <Grid item key={ key } {...{ xs: 6, sm: 4, md: 3, lg: 3 }} minHeight={160}>

                                        <img width="80%" src={ member.image } alt={ member.first_name + " " + member.last_name } />
                                        <h3>
                                            { member.first_name } {member.last_name}
                                        </h3>
                                        <p>
                                            <b>{ member.title}</b><br />
                                            { member.description }
                                        </p>
                                    </Grid>
                                );
                            }) 
                        }
                    </Grid>
                </Sectional>
                    

                <Sectional title="Advisory Board">
                    <Grid container spacing={ 2 } >
                        { 
                            advisoryBoard.map((member) => {
                                const key = member.first_name + "_" + member.last_name
                                return (
                                    <Grid item key={ key } {...{ xs: 12, sm: 6, md: 4, lg: 3 }} minHeight={160}>
                                        <h3>
                                            { member.first_name } {member.last_name}
                                        </h3>
                                        <h4>
                                            { member.title}
                                        </h4>
                                    </Grid>
                                );
                            }) 
                        }
                    </Grid>
                </Sectional>
            </>
        );
    }

    return (
        <>
        </>
    );
}