import React from "react";
import { Box, Container, Divider, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";

export const FooterWidget = () => {

    const socialMediaLinks = [
        {
            href: 'https://twitter.com/TheMADE',
            icon: '/img/icon/Twitter_Logo_Blue.svg',
            alt: 'Twitter: @TheMADE',
            text: 'Twitter @TheMADE',
            inverts: false,
        },
        {
            href: 'https://www.instagram.com/mademuseum/',
            icon: '/img/icon/instagram.svg',
            alt: 'Instagram: Made Museum',
            text: 'Instagram @mademuseum ',
            inverts: false,
        },
        {
            href: 'https://www.facebook.com/themuseumofartanddigitalentertainment/',
            icon: '/img/icon/facebook_logo.svg',
            alt: 'Facebook: The MADE',
            text: 'The MADE on Facebook',
            inverts: false,
        },
        {
            href: 'https://www.youtube.com/@TheMADEOak',
            icon: '/img/icon/youtube_logo.svg',
            alt: 'Youtube: The MADE',
            text: '@TheMADEOak on Youtube',
            inverts: false,
        },
        {
            href: 'https://www.twitch.tv/themadeoakland',
            icon: '/img/icon/twitch.svg',
            alt: 'TheMADEOakland on Twitch.TV',
            text: 'TheMADEOakland on Twitch.TV',
            inverts: false,
        },
        {
            href: 'https://www.patreon.com/themade',
            icon: '/img/icon/patreon.svg',
            alt: 'Support The MADE Patreon',
            text: 'Support The MADE Patreon',
            inverts: false,
        },
        {
            href: '/podcast',
            icon: '/img/icon/podcast.svg',
            alt: 'MADE PODCAST',
            text: 'Our Podcast: The MADECast',
            inverts: true,
        },
        {
            href: 'https://discord.gg/tMVcswHFjh',
            icon: '/img/icon/discord_2024.svg',
            alt: 'Join our Discord Server',
            text: 'The MADE Discord Server',
            inverts: false,
        },
        {
            href: 'https://www.guidestar.org/profile/26-4570976',
            icon: '/img/icon/guidestar_seal_2024.svg',
            alt: 'Candid: The MADE',
            text: 'Candid Platinum Transparency',
            inverts: false,
        },
        {
            href: 'mailto:info@themade.org',
            icon: '/img/icon/email_letter.svg',
            alt: 'Email The MADE: info@themade.org',
            text: 'Email The MADE: info@themade.org',
            inverts: true,
        }
    ];



    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () => createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light")),
        [prefersDarkMode]
    );

    const color = prefersDarkMode ? "0%" : "100%";
    const link_color = prefersDarkMode ? "white" : "black";

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Divider />
            <Container>
                <h2>Find us on Social Media</h2>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(50px, 1fr))',
                        gap: '16px'
                    }}
                >
                    {socialMediaLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: link_color, textDecoration: 'none' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '50px'
                                }}
                            >
                                <img
                                    style={{
                                        verticalAlign: 'middle',
                                        filter: link.inverts ? `invert(${color})` : 'none',
                                        paddingRight: '10px'
                                    }}
                                    width="50px"
                                    src={link.icon}
                                    alt={link.alt}
                                />
                            </Box>
                        </a>
                    ))}
                </Box>
                <br />
                <a
                    href="/"
                    style={{
                        color: link_color,
                        textDecoration: 'none',
                        textAlign: 'center',
                        display: 'block'
                    }}
                >
                    <h3>TheMADE.org 🕹️ 2009 - {currentYear}</h3>
                    <h3>501(c)(3) Nonprofit Organization 🎮 EIN: 26-4570976</h3>
                </a>
            </Container>
        </>
    );
};