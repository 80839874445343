import { Box, Button, Container, createTheme, useMediaQuery } from "@mui/material";
import { getDesignTokens } from "AppGlobals";
import { Sectional } from "layout/Sectional";
import Splash from "layout/Splash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventListProperties } from "widgets/Types/Types";

type EventProperties = {
    events: EventBlob[]
}

type EventBlob = {
    title: string | "Visit";
    "priority": Number;
    "description": string;
    "details": string[],
    "image": string,
    "link": string,
    "recurring": Boolean | false,
    "repeats": string | undefined,
    "time": {
        "start": { "utc": string, "local": string, "timezone": string },
        "end": { "utc": string, "local": string, "timezone": string }
    }
}

export const EventList: React.FC<EventListProperties> = ({ title, url, count }) => {
    const [error, setError] = useState(null);
    const [eventProperties, setEventProperties] = useState({});
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders.set('Pragma', 'no-cache');
    requestHeaders.set('Expires', '0');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Update the theme only if the mode changes
    const theme = React.useMemo(
        () =>
            createTheme(
                getDesignTokens(prefersDarkMode ? "dark" : "light")
            ),
        [prefersDarkMode]
    );

    useEffect(() => {
        fetch(url, { headers: requestHeaders })
            .then((res) => res.json())
            .then(
                (result) => {
                    setEventProperties(result);
                },
                (error) => {
                    setError(error);
                }
            );
    }, [url]);

    let events: EventBlob[] = (eventProperties as EventProperties).events;

    // Get the current time in UTC
    const currentTime = new Date().toISOString();

    // Filter events
    const filteredEvents = events
        ? events.filter((event) => {
            const now = new Date(); // Current time
            const eventEndTime = new Date(event.time.end.utc); // Event end time
            // having trouble with your event? Use this to check that the time entry is valid!
            // An example of a common mistake here: 
            // "2024-12-21T2:00:00Z", which is missing a leading zero for the hour field.
            /*
            console.log("Event:", event.title, {
                now,
                eventEndTime: event.time.end.utc,
                parsedEndTime: new Date(event.time.end.utc),
                isValid: !isNaN(new Date(event.time.end.utc).getTime())
            });
            */

            if (event.recurring) {
                // Always include recurring events
                return true;
            }

            // Ensure valid dates for non-recurring events
            return !isNaN(eventEndTime.getTime()) && eventEndTime >= now;
        })
        : [];

    return (
        <Container sx={{ overflowY: "auto", maxHeight: "100vh" }}>
            <Sectional title={title}
            >
                {filteredEvents &&
                    filteredEvents.slice(0, count).map((event, index) => (
                        <Button
                            sx={{
                                width: "100%",
                                padding: 0,
                                marginBottom: "20px",
                                position: "relative", // Ensures children are scoped correctly
                                overflow: "hidden", // Prevents overflow
                            }}
                            key={index}
                            href={event.link}
                            target={event.link.startsWith("http") ? "blank" : undefined}
                        >
                            <Box
                                sx={{
                                    height: "400px",
                                    width: "100%",
                                    zIndex: 999,
                                    top: 0,
                                    opacity: 0.8,
                                    margin: 0,
                                    paddingTop: 0,
                                    bgcolor: theme.palette.background.default,
                                    "&:hover": {
                                        opacity: 1.0,
                                    },
                                    overflowY: 'auto',
                                    maxHeight: '400px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        bgcolor: theme.palette.background.default,
                                        color: theme.palette.text.primary,
                                        padding: "30px",
                                        paddingTop: 0,
                                        marginTop: 0,
                                    }}
                                >
                                    <h1>{event.title}</h1>
                                    <h2>
                                        {event.recurring
                                            ? event.repeats
                                            : new Date(event.time.start.utc).toDateString()}
                                    </h2>
                                    <p>{event.description}</p>

                                    <Button variant="contained" color="secondary">
                                        Click for More Info
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    height: "400px",
                                    width: "100%",
                                    backgroundColor: "#000",
                                    zIndex: 998,
                                    opacity: 0.5,
                                    top: 0,
                                    margin: 0,
                                    position: "absolute",
                                }}
                            />
                            <Box
                                sx={{
                                    height: "400px",
                                    backgroundImage: `url(${event.image})`,
                                    backgroundSize: "cover",
                                    width: "100%",
                                    zIndex: 997,
                                    top: 0,
                                    margin: 0,
                                    position: "absolute",
                                }}
                            />
                        </Button>
                    ))}
            </Sectional>
        </Container>
    );
};